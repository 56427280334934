<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                    <div>
                        <b-row>
                          <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.application_log') }}</h5>
                          </b-col>
                        </b-row>
                        <b-table-simple striped bordered small>
                          <slot v-if="logs.length > 0">
                            <b-tr>
                              <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th>{{ $t('pump_install.send_from') }}</b-th>
                              <b-th>{{ $t('pump_install.send_to') }}</b-th>
                              <b-th>{{ $t('pump_install.notel') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(log,index) in logs" :key="log.id">
                              <b-td>{{ $n(sl+index) }}</b-td>
                              <b-td>{{ getOfficerName(log.sender_id) }}</b-td>
                              <b-td>{{ getOfficerName(log.receiver_id) }}</b-td>
                              <b-td>{{ $i18n.locale === 'bn' ? log.note_bn : log.note }}</b-td>
                            </b-tr>
                          </slot>
                          <slot v-else>
                            <b-tr>
                              <b-td colspan="4" class="text-center">No Data Found!!</b-td>
                            </b-tr>
                          </slot>
                        </b-table-simple>
                    </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { schemeAppLog } from '../../api/routes'

export default {
  props: ['id'],
  data () {
    return {
      loading: false,
      sl: 1,
      logs: [],
      userList: []
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getApplicationLog()
  },
  methods: {
    async getApplicationLog () {
      this.loading = true
      let result = null
      const applicationId = this.$props.id
      result = await RestApi.getData(irriSchemeServiceBaseUrl, schemeAppLog + '/' + applicationId)
      if (result.success) {
        this.logs = result.data
        this.userList = result.users.data
      } else {
        this.logs = []
      }
      this.loading = false
    },
    getOfficerName (officerId) {
      const officer = this.userList.find(officer => officer.value === officerId)
      const office = this.$store.state.commonObj.officeList.find(office => office.value === officer.office_id)
      if (this.$i18n.locale === 'bn') {
        return (officer !== undefined ? officer.text_bn : '') + ' (' + (office !== undefined ? office.text_bn : '') + ')'
      } else {
        return (officer !== undefined ? officer.text_en : '') + ' (' + (office !== undefined ? office.text_en : '') + ')'
      }
    }
  }
}
</script>
