<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.scheme_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="org_id"
            >
            <template v-slot:label>
              {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
            </template>
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              <p class="text-danger"> {{ errorMsz }} </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.scheme_typel')"
              label-for="scheme_type_id"
            >
              <b-form-select
                plain
                id="scheme_type_id"
                v-model="search.scheme_type_id"
                :options="SchemeTypeList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.division')"
              label-for="far_division_id"
            >
              <b-form-select
                plain
                id="far_division_id"
                v-model="search.far_division_id"
                :options="divisionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.district')"
              label-for="far_district_id"
            >
              <b-form-select
                plain
                id="far_district_id"
                v-model="search.far_district_id"
                :options="districtList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.upazila')"
              label-for="far_upazilla_id"
            >
              <b-form-select
                plain
                id="far_upazilla_id"
                v-model="search.far_upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.union')"
              label-for="far_union_id"
            >
              <b-form-select
                plain
                id="far_union_id"
                v-model="search.far_union_id"
                :options="unionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.mouza')"
              label-for="mouza"
            >
              <b-form-input
                plain
                id="mouza"
                v-model="search.mouza"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
            >
              <flat-pickr
                id="to_date"
                v-model="search.from_date"
                class="form-control"
                :placeholder="$t('globalTrans.select_date')">
              </flat-pickr>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.to_date')"
              label-for="to_date"
            >
              <flat-pickr
                id="to_date"
                v-model="search.to_date"
                class="form-control"
                :placeholder="$t('globalTrans.select_date')">
              </flat-pickr>
            </b-form-group>
          </b-col>
          <b-col class="text-right">
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12" v-show="tableShow">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.scheme_report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
            </b-button>
            <export-excel
                class="btn btn_add_new"
                :data="dataCustomizeEcel"
                :fields= "json_fields"
                :title="$t('pump_install.scheme_report')"
                worksheet="$t('pump_install.scheme_report')"
                name="excel.xls">
                <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(application_id)="data">
                      {{ $n(data.item.application_id, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(sch_man_name)="data">
                      {{ data.item.sch_man_name }}
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      <p>{{ data.item.far_village_bn }}</p>
                      <p>{{ data.item.union_name }}</p>
                      <p>{{ data.item.upazilla_name_bn }}</p>
                      <p>{{ data.item.district_name_bn }}</p>
                      <p>{{ data.item.division_name_bn }}</p>
                    </template>
                    <template v-slot:cell(division_name)="data" class="location">
                      <p>{{ data.item.far_village }}</p>
                      <p>{{ data.item.union_name }}</p>
                      <p>{{ data.item.upazilla_name }}</p>
                      <p>{{ data.item.district_name }}</p>
                      <p>{{ data.item.division_name }}</p>
                    </template>
                    <template v-slot:cell(far_mobile_no)="data">
                      {{ $n('0') + $n(data.item.far_mobile_no, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(view_application)="data">
                      <b-button v-b-modal.modal-7 variant=" iq-bg-success mr-1 mb-1" size="sm" title="View" @click="setRowId(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                      <b-button v-b-modal.log variant=" iq-bg-danger" size="sm" title="Application Log" @click="setRowId(data.item, 6)"><i class="ri-list-check"></i></b-button>
                    </template>
                    <template v-slot:cell(attachment)="data">
                      <a v-if="data.item.general_minutes !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/general-minutes/original/' + data.item.general_minutes" title="General Minute" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_lands !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-lands/original/' + data.item.scheme_lands" title="Scheme Land" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_map !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-map/original/' + data.item.scheme_map" title="Scheme Map"><i class="ri-download-cloud-fill"></i></a>
                    </template>
                    <template v-slot:cell(technical_survey_report)="data">
                      <b-button v-if="data.item.scheme_survey_id !== null" v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" size="sm" title="Show Report" @click="setRowId(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{dataCustomizeEcel}}</pre> -->
    <b-modal id="modal-6" size="lg" :title="$t('pump_install.survey_report')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <SurveyReport :id="id" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-7" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <ApplicationDetails :id="id" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="log" size="lg" :title="$t('pump_install.application_log')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Log :id="id" :key="id"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import SurveyReport from './SurveyReport'
import ApplicationDetails from '../SchemeApplicationDetails'
import Log from './Log'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerSchemeReportList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import excel from 'vue-excel-export'
import Vue from 'vue'
// import ExportPdf from '@/Utils/export-pdf'
import ExportPdf from './export-pdf_details'
// import HierarchycalNames from '@/Utils/common'
import { mapGetters } from 'vuex'
Vue.use(excel)

const excelColumn = {
  'Application ID': 'appId',
  'Applicant Name': 'appName',
  'Mobile Number': 'mNumber',
  Location: 'location',
  NID: 'Nid',
  Notes: 'Notes',
  'Scheme Type': 'sType',
  Status: 'far_status'
}
const excelColumnBn = {
  'আবেদনের আইডি': 'appId',
  'আবেদনকারীর নাম': 'appName',
  'মোবাইল নম্বর': 'mNumber',
  অবস্থান: 'location',
  এনআইডি: 'Nid',
  মন্তব্য: 'Notes',
  'সেচের ধরণ': 'sType',
  অবস্থা: 'far_status'
}
export default {
  mixins: [ModalBaseMasterList],
  components: {
    SurveyReport,
    ApplicationDetails,
    Log
  },
  data () {
    return {
      tableShow: false,
      dataShow: false,
      baseUrl: irriSchemeServiceBaseUrl,
      search: {
        scheme_type_id: 0,
        org_id: 0,
        far_division_id: 0,
        far_district_id: 0,
        far_upazilla_id: 0,
        far_union_id: 0,
        mouza: '',
        nid: ''
      },
      errorMsz: '',
      id: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      status: '',
      farApplicationStatusList: [
        {
          value: 1,
          text: 'Pending',
          text_bn: 'বিচারাধীন'
        },
        {
          value: 2,
          text: 'Processing',
          text_bn: 'প্রক্রিয়াধীন'
        },
        {
          value: 3,
          text: 'Approved',
          text_bn: 'অনুমোদিত'
        },
        {
          value: 4,
          text: 'Reviewed',
          text_bn: 'পর্যালোচিত'
        },
        {
          value: 5,
          text: 'Rejected',
          text_bn: 'প্রত্যাখ্যাত'
        },
        {
          value: 6,
          text: 'Current Status',
          text_bn: 'বর্তমান অবস্থা'
        },
        {
          value: 7,
          text: 'Verified',
          text_bn: 'যাচাইকৃত'
        },
        {
          value: 8,
          text: 'Aggrement',
          text_bn: 'চুক্তি'
        },
        {
          value: 9,
          text: 'Requisition',
          text_bn: 'চাহিদাপত্র'
        },
        {
          value: 10,
          text: 'Supply',
          text_bn: 'সরবরাহ'
        },
        {
          value: 11,
          text: 'Installation',
          text_bn: 'স্থাপন'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    json_fields: function () {
        return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeEcel () {
        const listData = this.listData
        listData.map(item => {
            if (this.$i18n.locale === 'bn') {
                item.appId = item.application_id
                item.appName = item.name_bn
                item.mNumber = item.far_mobile_no
                item.location = item.far_village_bn + ', ' + item.union_name_bn + ', ' + item.upazilla_name_bn + ', ' + item.district_name_bn + ', ' + item.division_name_bn
                item.Nid = item.nid
                item.Notes = ''
                item.sType = item.scheme_type_name_bn
                item.far_status = item.far_app_status_bn
            } else {
                item.appId = item.application_id
                item.appName = item.name
                item.mNumber = item.far_mobile_no
                item.location = item.far_village + ', ' + item.union_name + ', ' + item.upazilla_name + ', ' + item.district_name + ', ' + item.division_name
                item.Nid = item.nid
                item.Notes = ''
                item.sType = item.scheme_type_name
                item.far_status = item.far_app_status
            }
            return Object.assign({}, item)
        })
        return listData
    },
    formTitle () {
      return (this.id === 0) ? this.$t('irrigation_config.scheme_type_entry') : this.$t('irrigation_config.scheme') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('pump_install.application_id'), class: 'text-left' },
          { label: this.$t('pump_install.applicant_name'), class: 'text-left' },
          { label: this.$t('pump_install.mobile_number'), class: 'text-left' },
          { label: this.$t('globalTrans.location'), class: 'text-left' },
          { label: this.$t('globalTrans.view_application'), class: 'text-left' },
          { label: this.$t('pump_install.attachment'), class: 'text-left' },
          { label: this.$t('pump_install.survey_report'), class: 'text-left' },
          { label: this.$t('pump_install.notel'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'far_mobile_no' },
          { key: 'division_name_bn' },
          { key: 'view_application' },
          { key: 'attachment' },
          { key: 'technical_survey_report' },
          { key: 'note' },
          { key: 'far_app_status_bn' }
          ]
      } else {
          keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'far_mobile_no' },
          { key: 'division_name' },
          { key: 'view_application' },
          { key: 'attachment' },
          { key: 'technical_survey_report' },
          { key: 'note' },
          { key: 'far_app_status' }
          ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    SchemeTypeList: function () {
      const schemeTypes = this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(item => item.status === 0)
      const tmpList = schemeTypes.map(item => {
          if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text }
          }
      })
      return tmpList
    }
  },
  watch: {
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  created () {
    // this.loadData()
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    // this.loadData()
  },
  methods: {
    searchData () {
      if (this.search.org_id !== 0) {
        this.errorMsz = ''
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.org_id
          })
        } else if (this.authUser.role_id === 0) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.office_detail.org_id,
            far_division_id: this.authUser.office_detail.division_id,
            far_district_id: this.authUser.office_detail.district_id,
            far_upazilla_id: this.authUser.office_detail.upazilla_id
          })
        }
        this.loadData()
      } else {
        this.errorMsz = 'Requird'
      }
    },
    setRowId (item, status = 1) {
      this.id = item.id
      this.status = status
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, farmerSchemeReportList, this.search).then(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.tableShow = true
            this.$store.dispatch('setList', this.relationalData(response.data))
          }
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    relationalData (data) {
      const organizationList = this.$store.state.orgList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const schemeTypes = this.$store.state.IrriConfig.commonObj.schemeTypeList
      const listData = data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const unionObject = unionList.find(union => union.value === item.union_id)
        const statusListObject = this.farApplicationStatusList.find(appStatus => appStatus.value === item.status)
        const schemeTypeObject = schemeTypes.find(schemeType => schemeType.value === item.scheme_type_id)
        const orgData = {
          org_name: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
        }
        const divisionData = {
            division_name: divisionObject !== undefined ? divisionObject.text_en : '',
            division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
            district_name: districtObject !== undefined ? districtObject.text_en : '',
            district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
            upazilla_name: upazilaObject !== undefined ? upazilaObject.text_en : '',
            upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
        }
        const unionData = {
            union_name: unionObject !== undefined ? unionObject.text_en : '',
            union_name_bn: unionObject !== undefined ? unionObject.text_bn : ''
        }
        const farAppStatusData = {
          far_app_status: statusListObject !== undefined ? statusListObject.text_en : '',
          far_app_status_bn: statusListObject !== undefined ? statusListObject.text_bn : ''
        }
        const schemeTypeOData = {
          scheme_type_name: schemeTypeObject !== undefined ? schemeTypeObject.text_en : '',
          scheme_type_name_bn: schemeTypeObject !== undefined ? schemeTypeObject.text_bn : ''
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, farAppStatusData, schemeTypeOData)
      })
      return listData
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Scheme Report' : 'স্কিম প্রতিবেদন'
      ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', 3, reportTitle, this.listData, this, this.search)
    },
    dataCustomize () {
      const pumpInformations = this.listData
      // pumpInformations.map(item => {
      //   item.pump_id_local = this.$n(item.pump_id)
      //   if (this.$i18n.locale === 'bn') {
      //     item.area = item.area_bn
      //   } else {
      //     item.area = item.area_en
      //   }
      //   return Object.assign({}, item)
      // })
      return pumpInformations
    }
  }
}
</script>
<style scoped>
p[data-v-745cfd20]{
  margin: 0px !important;
  padding: 0px !important;
}
</style>
