import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'
// function getMonth (month) {
//     month = parseInt(month)
//     if (month === 1) {
//         return i18n.locale === 'bn' ? 'জুলাই' : 'July'
//     } else if (month === 2) {
//         return i18n.locale === 'bn' ? 'আগস্ট' : 'August'
//     } else if (month === 3) {
//         return i18n.locale === 'bn' ? 'সেপ্টেম্বর' : 'September'
//     } else if (month === 4) {
//         return i18n.locale === 'bn' ? 'অক্টোবর' : 'October'
//     } else if (month === 5) {
//         return i18n.locale === 'bn' ? 'নভেম্বর' : 'November'
//     } else if (month === 6) {
//         return i18n.locale === 'bn' ? 'ডিসেম্বর' : 'December'
//     } else if (month === 7) {
//         return i18n.locale === 'bn' ? 'জানুয়ারী' : 'January'
//     } else if (month === 8) {
//         return i18n.locale === 'bn' ? 'ফেব্রুয়ারী' : 'February'
//     } else if (month === 9) {
//         return i18n.locale === 'bn' ? 'মার্চ' : 'March'
//     } else if (month === 10) {
//         return i18n.locale === 'bn' ? 'এপ্রিল' : 'April'
//     } else if (month === 11) {
//         return i18n.locale === 'bn' ? 'মে' : 'May'
//     } else if (month === 12) {
//         return i18n.locale === 'bn' ? 'জুন' : 'June'
//     }
//   }
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = [
          []
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['15%', '2%', '13%', '15%', '2%', '18%', '15%', '2%', '18%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.application_id'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.applicant_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.mobile_number'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.location'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.nid'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.notel'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.scheme_type'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' }
          ]
        ]
        data.map((report, index) => {
          allRows.push(
          [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: vm.$n(report.application_id, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: report.appName, style: 'td', alignment: 'right' },
            { text: vm.$n('0') + vm.$n(report.mNumber, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: report.location, style: 'td', alignment: 'right' },
            { text: vm.$n(report.nid, { useGrouping: false }), style: 'td', alignment: 'right' },
            { text: report.Notes, style: 'td', alignment: 'right' },
            { text: report.sType, style: 'td', alignment: 'right' },
            { text: report.far_status, style: 'td', alignment: 'right' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['10%', '10%', '15%', '10%', '10%', '10%', '10%', '15%', '10%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            th1: {
                fontSize: 9
              },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            search: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            krishi: {
              margin: [0, 1, 0, 15],
              alignment: 'center'
            },
            header3: {
              fontSize: 9,
              margin: [0, 15, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
